<template>
    <div class="w-100">
        <!-- Header @start -->
        <Header />
        <!-- Header @end -->

        <!-- Back Button @start-->
        <b-row v-if="!isMobileMenu" class="w-100 mt-6 px-3 is-mobile">
            <b-col cols="12" class="mb-3 zc-text-format">
                <!-- arrow-left.svg -->
                <div @click="goBackToChatList" style="width: fit-content;" class="go-back">
                    <img src="@/assets/zaajira-candidate/assets/icons/arrow-left.svg" width="19px" height="19px"
                        class="img-fluid" />
                    <span style="font-size: 1rem;" class="pl-3">{{ $gettext("Chat") }}</span>
                </div>
            </b-col>
        </b-row>
        <!-- Back Button @end-->

        <b-row class="p-3 m-0" :class="isMobileMenu ? 'mt-6' : 'zc-mt-lg-6-md-0'">
            <b-col cols="12" col lg="3" md="12" sm="12" style="min-height: 80vh; max-height: fit-content;"
                :class="isMobileMenu ? 'is-mobile' : 'is-desktop'" class="zc-container">
                <b-row>
                    <b-col cols="12">
                        <!-- Chat Search @start -->
                        <b-form style="position: relative;" class="customized-form chat-search d-flex  mt-3">
                            <img style="position: absolute;bottom: 30px;right: 17px;"
                                src="@/assets/zaajira-candidate/assets/icons/search-normal.svg" width="19px" height="19px"
                                class="img-fluid" />
                            <b-form-input
                                style="padding-left: 2rem; border-radius:5px; background-color: var(--neutral-white,#fff);"
                                class="form-control mr-sm-2" size="sm"
                                :placeholder='$gettext("Search_name_chat")'></b-form-input>
                        </b-form>
                        <!-- Chat Search @end -->
                        <!-- Chat NavBar @start -->
                        <b-row class="px-3" style="padding-bottom: 0.7rem; border-bottom: 1px solid #00000052;">
                            <b-col>
                                <span style="cursor: pointer;" @click="AllChats"
                                    :style="activeChatTab === 'All' ? 'opacity:1;' : 'opacity:.5;'"
                                    :class="{ 'chat-active-navBar': activeChatTab === 'All' }"
                                    class="generic-typography chat-navBar mr-2">{{ $gettext("All") }}</span>
                                <span style="cursor: pointer;" @click="unReadChats"
                                    :style="activeChatTab === 'Unread' ? 'opacity:1;' : 'opacity:.5;'"
                                    :class="{ 'chat-active-navBar': activeChatTab === 'Unread' }"
                                    class="generic-typography chat-navBar">{{ $gettext("Unread") }}</span>

                            </b-col>
                        </b-row>
                        <!-- Chat NavBar @end -->
                    </b-col>
                    <b-col v-if="activeChatTab === 'All' && chatList.length > 0" cols="12"
                        style="overflow: auto; height: 65vh;">
                        <!-- user chat list @start -->
                        <div class=" d-flex justify-content-between px-3 pt-3" v-for="(chatuser, i) in chatList" :key="i"
                            style="padding-bottom: 0.8rem; border-bottom: 1px solid #00000042;">
                            <!-- style="padding-bottom: 0.8rem; border-bottom: 1px solid #00000042;" -->
                            <div class="d-flex flex-row" style="width:80%; ">
                                <b-avatar style="background: #F1F1F1;" size="3rem">
                                    <img v-if="chatuser.avtar" :src="chatuser.avtar" width="75%" height="auto"
                                        class="img-fluid" />
                                </b-avatar>
                                <div class="d-flex flex-column pl-2" @click="getMessage(chatuser)">
                                    <span style="cursor: pointer; font-size:1rem ;" class="h-job-name">{{ chatuser.company
                                    }}
                                    </span>
                                    <span style="font-size: 0.875rem;"
                                        :style="chatuser.isRead ? `opacity:1; width:${ellipsis}px;` : `opacity:.5;width:${ellipsis}px;`"
                                        class="generic-typography zc-text-ellipsis">
                                        {{ chatuser.message !== null && chatuser.message !== "null" ? chatuser.message : ''
                                        }}
                                    </span>
                                </div>
                            </div>
                            <div style="gap:10px; width: 20%" class="d-flex flex-column align-items-end">
                                <div class="d-flex justify-content-between align-items-start">
                                    <span class="text-left text-nowrap">{{ chatuser.timesince }}</span>
                                </div>
                                <div class="d-flex justify-content-between align-items-center">
                                    <svg v-if="chatuser.isRead" xmlns="http://www.w3.org/2000/svg" width="8" height="8"
                                        viewBox="0 0 8 8" fill="none">
                                        <circle cx="4" cy="4" r="4" fill="#F36F21" />
                                    </svg>

                                    <div v-if="chatuser.isDelivered"> <img
                                            src="@/assets/zaajira-candidate/assets/icons/delivered.svg" width="75%"
                                            height="auto" class="img-fluid" /></div>
                                    <div v-if="!chatuser.isDelivered && chatuser.isDelivered !== undefined"> <img
                                            src="@/assets/zaajira-candidate/assets/icons/not-delivered.svg" width="75%"
                                            height="auto" class="img-fluid" /></div>
                                </div>

                            </div>
                        </div>

                        <!-- user chat list @end -->
                    </b-col>
                    <b-col v-else-if="activeChatTab === 'Unread' && unreadChatList.length > 0" cols="12"
                        style="overflow: auto; height: 65vh;">
                        <!-- user chat list @start -->
                        <div class=" d-flex justify-content-between px-3 pt-3" v-for="(chatuser, i) in chatList" :key="i"
                            style="padding-bottom: 0.8rem; border-bottom: 1px solid #00000042;">
                            <!-- style="padding-bottom: 0.8rem; border-bottom: 1px solid #00000042;" -->
                            <div class="d-flex flex-row" style="width:80%; ">
                                <b-avatar style="background: #F1F1F1;" size="3rem">
                                    <img v-if="chatuser.avtar" :src="chatuser.avtar" width="75%" height="auto"
                                        class="img-fluid" />
                                </b-avatar>
                                <div class="d-flex flex-column pl-2" @click="getMessage(chatuser)">
                                    <span style="cursor: pointer; font-size:1rem ;" class="h-job-name">{{ chatuser.company
                                    }}
                                    </span>
                                    <span style="font-size: 0.875rem;"
                                        :style="chatuser.isRead ? `opacity:1; width:${ellipsis}px;` : `opacity:.5;width:${ellipsis}px;`"
                                        class="generic-typography zc-text-ellipsis">
                                        {{ chatuser.message !== null && chatuser.message !== "null" ? chatuser.message : ''
                                        }}
                                    </span>
                                </div>
                            </div>
                            <div style="gap:10px; width: 20%" class="d-flex flex-column align-items-end">
                                <div class="d-flex justify-content-between align-items-start">
                                    <span class="text-left text-nowrap">{{ chatuser.timesince }}</span>
                                </div>
                                <div class="d-flex justify-content-between align-items-center">
                                    <svg v-if="chatuser.isRead" xmlns="http://www.w3.org/2000/svg" width="8" height="8"
                                        viewBox="0 0 8 8" fill="none">
                                        <circle cx="4" cy="4" r="4" fill="#F36F21" />
                                    </svg>

                                    <div v-if="chatuser.isDelivered"> <img
                                            src="@/assets/zaajira-candidate/assets/icons/delivered.svg" width="75%"
                                            height="auto" class="img-fluid" /></div>
                                    <div v-if="!chatuser.isDelivered && chatuser.isDelivered !== undefined"> <img
                                            src="@/assets/zaajira-candidate/assets/icons/not-delivered.svg" width="75%"
                                            height="auto" class="img-fluid" /></div>
                                </div>

                            </div>
                        </div>

                        <!-- user chat list @end -->
                    </b-col>

                    <b-col v-else cols="12" style="overflow: auto; height: 65vh;">
                    </b-col>
                </b-row>

            </b-col>
            <b-col :class="isDesktop ? 'is-desktop' : 'is-mobile'" col lg="9" md="12" sm="12"
                style="min-height: 80vh;max-height: fit-content;">
                <div v-if="chatHeader !== null" style="position: relative; height: 80vh;" class="zc-container">
                    <!-- chat topBar @start -->
                    <div class="d-flex align-items-center p-3"
                        style="height: 6.25rem;border-radius: 0.5625rem;background: var(--neutral-white, #FFF);">
                        <div style="gap:.5rem" class="d-flex ">
                            <b-avatar style="background: #F1F1F1;"
                                src="@/assets/zaajira-candidate/assets/bg-images/avtar-image.png" class="">
                                <img v-if="chatHeader.avtar !== null" :src="chatHeader.avtar" class="img-fluid" />
                            </b-avatar>

                            <span>
                                <p style="cursor: pointer;" class="h-label m-0">
                                    {{ chatHeader.company !== undefined ? chatHeader.company : '' }}
                                </p>
                                <p class="h-title m-0">

                                    Online
                                    <svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 8 8"
                                        fill="none">
                                        <circle cx="4" cy="4" r="4" fill="#25C78B" />
                                    </svg>

                                </p>
                            </span>
                        </div>
                    </div>
                    <!-- chat topBar @end -->

                    <!-- chat messages @start -->
                    <div id="chat-wrapper" style="height: 58vh; overflow:auto;" class="px-3">
                        <div v-for="(m, i) in chatMessages" :key="i">
                            <!-- <div> -->
                            <!-- sender @start -->
                            <!-- sender @end -->
                            <div v-if="m.sender_role_id === 3" class="d-flex flex-column align-items-end mt-3">
                                <div class="chat-container sent-chat text-right">
                                    <div class="d-flex flex-row">
                                        <div v-if="m.documents !== undefined && m.documents.length" class="d-flex flex-row">
                                            <div style="cursor: pointer;" v-for="(doc, i) in m.documents" :key="i"
                                                class="chat-pdf mr-2">
                                                <!-- <i style="color: red;" class="fa fa-file-pdf-o fa-5x" aria-hidden="true"></i> -->
                                                <img src="@/assets/zaajira-candidate/assets/icons/pdf-icon.svg" width="42px"
                                                    height="42px" class="img-fluid" />
                                                <span
                                                    style="font-size: 0.75rem;opacity: 0.5;width: 4.3rem;overflow: hidden;text-wrap: nowrap;text-overflow: ellipsis;"
                                                    class="generic-typography">{{
                                                        doc.doc_name }}</span>
                                            </div>
                                        </div>
                                        <div v-if="m.doc_id !== null && m.doc_name !== null && m.doc_path !== null"
                                            class="d-flex flex-row">
                                            <div style="cursor: pointer;" class="chat-pdf mr-2">
                                                <!-- <i style="color: red;" class="fa fa-file-pdf-o fa-5x"
                                                    aria-hidden="true"></i> -->
                                                <!-- <img src="@/assets/zaajira-candidate/assets/icons/pdf-icon.svg" width="42px"
                                                    height="42px" class="img-fluid" /> -->
                                                <i :class="`${getFontAwesomeIconClass(m.doc_name)}`" aria-hidden="true"></i>

                                                <span
                                                    style="font-size: 0.75rem;opacity: 0.5;width: 4.3rem;overflow: hidden;text-wrap: nowrap;text-overflow: ellipsis;"
                                                    class="generic-typography">
                                                    <a :href="`${IMAGEURL}${m.doc_path}`" target="_blank"
                                                        rel="noopener noreferrer">

                                                        {{ m.doc_name }}
                                                    </a>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <p style="font-family: var(--font-family-sans-serif); font-size: .875rem; margin: 0;">
                                        {{ m.message !== null && m.message !== "null" ? m.message : '' }}
                                    </p>
                                </div>
                                <span class="chat-time">{{ convertToTimeSince(m.created_at) }} ago</span>
                            </div>

                            <div v-else class="d-flex flex-column align-items-start mt-3">
                                <div style="align-items: flex-start; border-radius: 10px 10px 10px 0px;"
                                    class="chat-container card text-left">
                                    <div class="d-flex flex-row">
                                        <div v-if="m.documents !== undefined && m.documents.length">
                                            <div v-for="(doc, i) in m.documents" :key="i" class="chat-pdf mr-2">
                                                <!-- <i style="color: red;" class="fa fa-file-pdf-o fa-5x" aria-hidden="true"></i> -->
                                                <img src="@/assets/zaajira-candidate/assets/icons/pdf-icon.svg" width="42px"
                                                    height="42px" class="img-fluid" />
                                                <span
                                                    style="font-size: 0.75rem;opacity: 0.5;width: 4.3rem;overflow: hidden;text-wrap: nowrap;text-overflow: ellipsis;"
                                                    class="generic-typography">{{
                                                        doc.doc_name }}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <p style="font-family: var(--font-family-sans-serif); font-size: .875rem;margin: 0;">
                                        {{ m.message !== null ? m.message : '' }}
                                    </p>
                                </div>
                                <span class="chat-time">{{ convertToTimeSince(m.created_at) }} ago</span>
                            </div>
                        </div>

                        <div v-if="(uploadPercentage !== null || message !== null) && sending"
                            class="d-flex flex-column align-items-end mt-3">
                            <div class="chat-container sent-chat text-right">
                                <div v-if="uploadPercentage !== null" class="d-flex flex-row">
                                    <div class="chat-pdf mr-2">
                                        <i :class="`${uploadingFileIcon}`" aria-hidden="true"></i>
                                        {{ uploadPercentage }}
                                    </div>
                                </div>
                                <p v-if="message !== null"
                                    style="font-family: var(--font-family-sans-serif); font-size: .875rem;">
                                    {{ message }}
                                </p>
                            </div>
                            <span class="chat-time"><i class="fa fa-clock-o" aria-hidden="true"></i></span>
                        </div>


                    </div>
                    <!-- chat messages @end -->

                    <!-- Chat Search @start -->
                    <b-form class="customized-form file-input">
                        <b-form-input id="chat-input-box"
                            style="padding-left: 0.5rem;padding-right: 4rem;width: 100%;border-radius: 5px;background-color: var(--neutral-white, #FFF);"
                            class="form-control mr-sm-2" size="sm" v-model="message" @keyup.enter="sendChat"
                            :placeholder='$gettext("Send_your_message")'></b-form-input>
                        <span style="position:absolute; top: 12px;right: 15px;opacity: 1;">
                            <!-- <input style="position: fixed; left: -1000000px;" type="file" name="" id="attach-file"> -->
                            <!-- <b-form-file style="position: fixed; left: -1000000px;" v-model="attachedFile" id="attach-file"
                                @change="uploadDoc"
                                accept="image/*, video/*,.pdf,.doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.documen, "></b-form-file>
                            <label for="attach-file">
                                <img src="@/assets/zaajira-candidate/assets/icons/attach-file.svg" width="25px"
                                    height="25px" class="img-fluid" mr-1 />
                            </label> -->
                            <span class="chat-send-icon">
                                <img @click="sendChat" src="@/assets/zaajira-candidate/assets/icons/send-chat.svg"
                                    class="img-fluid" width="25px" height="25px" />
                            </span>
                        </span>
                    </b-form>
                    <!-- Chat Search @end -->
                </div>
                <!-- <div v-else style="position: relative; height: 80vh;" class="zc-container"></div> -->
            </b-col>
        </b-row>

    </div>
</template>

<script>
import Header from '../../../components/Partials/Header.vue'
import { convertTime } from "@/helpers/HelperFunctions.js";
import CandidateService from "@/apiServices/CandidateService";
import avtar from "@/assets/zaajira-candidate/assets/icons/ph_user-bold.svg";
import { IMAGEURL } from "@/config";
import moment from "moment";
export default {
    name: "ChatScreen",
    components: { Header },
    data() {
        return {
            IMAGEURL,
            attachedFile: [],
            activeChatTab: 'All',
            isChatList: false,
            isDesktop: true,
            isMobileMenu: window.innerWidth <= 992,
            ellipsis: window.innerWidth,
            interval: null,
            unreadChatList: [],
            chatList: [
                // {
                //     avtar: 'apple.png',
                //     company: "Apple.inc",
                //     message: "Sure! let me tell you about what hap…",
                //     isRead: false,
                //     timesince: "2m Ago",
                // },
                // {
                //     avtar: 'Airbnb.png',
                //     company: "Airbnb",
                //     message: "Lorem ipsum dolor sit amet consectetur...",
                //     isRead: true,
                //     timesince: "2 m Ago",
                // },
                // {
                //     avtar: "amazon.svg",
                //     company: "Amazon",
                //     message: "Morning dear, I have question!",
                //     isDelivered: true,
                //     timesince: "2 m Ago",
                // },
                // {
                //     avtar: 'adobe.png',
                //     company: "Adobe",
                //     message: "You :Lorem ipsum dolor sit amet ",
                //     isDelivered: false,
                //     timesince: "2 m Ago",
                // },
                // {
                //     avtar: 'apple.png',
                //     company: "Apple.inc",
                //     message: "Sure! let me tell you about what hap…",
                //     isRead: false,
                //     timesince: "2 m Ago",
                // },
                // {
                //     avtar: 'Airbnb.png',
                //     company: "Airbnb",
                //     message: "Lorem ipsum dolor sit amet consectetur...",
                //     isRead: true,
                //     timesince: "2 m Ago",
                // },
                // {
                //     avtar: "amazon.svg",
                //     company: "Amazon",
                //     message: "Morning dear, I have question!",
                //     isDelivered: true,
                //     timesince: "2 m Ago",
                // },
                // {
                //     avtar: 'adobe.png',
                //     company: "Adobe",
                //     message: "You :Lorem ipsum dolor sit amet ",
                //     isDelivered: false,
                //     timesince: "2 m Ago",
                // },

            ],
            chatHeader: null,
            sending: false,
            uploadingFileIcon: null,
            uploadPercentage: null,
            message: null,
            chatMessages: [
                // {
                //     "message_id": 1,
                //     "sender_role_id": 2,
                //     "company_id": 9,
                //     "from": 101,
                //     "to": 1,
                //     "message": "Hello Faizan! I'm Steve from apple.inc, I see you applied in our last job post as a UI designer. Unfortunately I can't see your CV that you attached in file. Can you please send it to me here. Thank you",
                //     "documents": [],
                //     "emp_msg_status": "sent",
                //     "app_msg_status": "recieved",
                //     "active": "Y",
                //     "created_at": "2023-10-12T12:20:09.000Z"
                // },
                // {
                //     "message_id": 2,
                //     "sender_role_id": 3,
                //     "company_id": 9,
                //     "from": 1,
                //     "to": 101,
                //     "message": "Morning apple, I have question about the this job",
                //     "documents": [],
                //     "emp_msg_status": "recieved",
                //     "app_msg_status": "sent",
                //     "active": "Y",
                //     "created_at": "2023-10-21T09:01:50.000Z"
                // },
                // {
                //     "message_id": 3,
                //     "sender_role_id": 2,
                //     "company_id": 9,
                //     "from": 101,
                //     "to": 1,
                //     "message": "Yes sure, Any problem to implement the job?",
                //     "documents": [],
                //     "emp_msg_status": "recieved",
                //     "app_msg_status": "sent",
                //     "active": "Y",
                //     "created_at": "2023-10-21T09:01:50.000Z"
                // },
                // {
                //     "message_id": 4,
                //     "sender_role_id": 3,
                //     "company_id": 9,
                //     "from": 1,
                //     "to": 101,
                //     "message": "Also you can visit my portfilio www.behance.com",
                //     documents: [
                //         {
                //             doc_id: 1,
                //             doc_name: "CV1.pdf",
                //             doc_path: "http://localhost:8080/CV1.pdf"
                //         },
                //         {
                //             doc_id: 2,
                //             doc_name: "CV2.pdf",
                //             doc_path: "http://localhost:8080/CV1.pdf"
                //         }
                //     ],
                //     "emp_msg_status": "recieved",
                //     "app_msg_status": "sent",
                //     "active": "Y",
                //     "created_at": "2023-10-21T09:01:50.000Z"
                // },
                // {
                //     "message_id": 5,
                //     "sender_role_id": 3,
                //     "company_id": 9,
                //     "from": 1,
                //     "to": 101,
                //     "message": "Lorem ipsum dolor sit amet consectetur. Vel vulputate posuere at sed.",
                //     "documents": [],
                //     "emp_msg_status": "recieved",
                //     "app_msg_status": "sent",
                //     "active": "Y",
                //     "created_at": "2023-10-21T09:01:50.000Z"
                // },
            ]
        }
    },
    methods: {
        convertToTimeSince(created_at) {
            const now = moment();
            const created = moment(created_at).format("YYYY-MM-DD HH:mm:ss");
            let diff = now.diff(created, "minutes");
            const timeSince = convertTime(diff)
            return timeSince
        },
        goBackToChatList() {
            if (this.isMobileMenu && this.isDesktop) {
                this.isDesktop = !this.isDesktop
                this.isMobileMenu = !this.isMobileMenu
            } else {
                this.isDesktop = true
                this.isMobileMenu = window.innerWidth <= 992
            }
        },
        async getChatList() {
            try {
                const chatListRes = await CandidateService.GetChatList()
                //console.log('chatListRes', chatListRes);

                const userChatList = []
                chatListRes.data.data.userList.map((chat) => {
                    // const isReadOrisDelivered = this.checkMessageDeliveredOrRead(chat)
                    const c = {
                        user_id: chat.user_id,
                        company_id: chat.cmp_id,
                        job_id: chat.job_id,
                        avtar: `${IMAGEURL}${chat.company_logo_path}`,
                        company: chat.company_long_name,
                        message: chat.message.length ? chat.message[0].message : '',
                        timesince: chat.message.length ? this.convertToTimeSince(chat.message[0].created_at) : '',
                        // ...isReadOrisDelivered
                        ...this.checkMessageDeliveredOrRead(chat)
                    }
                    userChatList.push(c)
                })
                return userChatList

            } catch (error) {
                //console.log(error);
            }
        },
        unReadChats() {
            this.activeChatTab = 'Unread'
            this.unreadChatList = this.chatList.filter((c) => c?.isRead === 'N')
        },
        AllChats() {
            //console.log("aman");
            this.activeChatTab = 'All'
        },
        async getMessage(chatUser) {
            //console.log("chatUser", chatUser);
            this.chatHeader = chatUser
            const loader = this.$loading.show()
            try {
                const chatMsgRes = await CandidateService.GetChatMessages(chatUser.user_id);
                //console.log("chatMsgRes", chatMsgRes.data.data.senderReceiver);
                this.chatMessages = chatMsgRes.data.data.senderReceiver
                this.goBackToChatList()
                const chatWrapper = document.getElementById('chat-wrapper')
                chatWrapper.scrollTo(0, chatWrapper.scrollHeight);
                loader.hide()
                this.getLastMessage()
            } catch (error) {
                loader.hide()
                //console.log(error);
            }
        },
        checkMessageDeliveredOrRead(chat) {
            const userData = this.$store.getters.getLoggedUser
            if (chat.from === userData.userId) {
                if (chat.emp_msg_status === "recieved" && chat.app_msg_status === "sent") {
                    return { isDelivered: true }
                } else {
                    return { isDelivered: false }
                }
            } else {
                if (chat.emp_msg_status === "sent" && chat.app_msg_status === "recieved") {
                    return { isRead: true }
                } else {
                    return { isRead: false }
                }

            }
        },
        async getLastMessage() {
            this.interval = setInterval(async () => {
                this.chatList = await this.getChatList()
                // const loader = this.$loading.show()
                try {
                    const chatMsgRes = await CandidateService.GetChatMessages(this.chatHeader.user_id);
                    //console.log("chatMsgRes", chatMsgRes.data.data.senderReceiver);
                    this.chatMessages = chatMsgRes.data.data.senderReceiver
                    const chatWrapper = document.getElementById('chat-wrapper')
                    chatWrapper.scrollTo(0, chatWrapper.scrollHeight);

                    // loader.hide()

                } catch (error) {
                    // loader.hide()
                    //console.log(error);
                }
            }, 5000);
            // clearInterval(this.interval);
        },
        filterValidMimeTypes(mimeType) {
            // const documentRegex = /^(application\/(pdf|msword|vnd\.openxmlformats-officedocument\.wordprocessingml\.document)|text\/(plain|html))$/;
            const imageRegex = /^image\//;
            const videoRegex = /^video\//;
            const wordRegex = /^application\/(?:msword|vnd\.openxmlformats-officedocument\.wordprocessingml\.document)$/;
            const pdfRegex = /^application\/pdf/;

            if (imageRegex.test(mimeType)) {
                return 'fa fa-picture-o img-vid-file';
            }
            else if (wordRegex.test(mimeType)) {
                return 'fa fa-file-word-o word-file';
            }
            else if (pdfRegex.test(mimeType)) {
                return 'fa fa-file-pdf-o pdf-file';
            }
            else if (videoRegex.test(mimeType)) {
                return 'fa fa-file-video-o img-vid-file';
            } else {
                return 'fa fa-file file';
            }

        },
        getFontAwesomeIconClass(fileName) {
            const extension = fileName.split('.').pop().toLowerCase();

            switch (extension) {
                case 'mp4':
                    return 'fa fa-picture-o img-vid-file';
                case 'pdf':
                    return 'fa fa-file-pdf-o pdf-file';
                case 'doc':
                case 'docx':
                    return 'fa fa-file-word-o word-file';
                case 'jpg':
                case 'jpeg':
                case 'png':
                    return 'fa fa-picture-o img-vid-file';
                default:
                    return 'fa fa-file file';
            }
        },
        uploadDoc(e) {
            this.sending = true
            //console.log("e.target.files", e.target.files);
            //console.log("e.target.files", e.target.files[0].type);
            this.uploadingFileIcon = this.filterValidMimeTypes(e.target.files[0].type)
            //console.log("uploadingFileIcon", this.uploadingFileIcon);

            const userData = this.$store.getters.getLoggedUser
            let form_data = new FormData();
            form_data.append('user_id', this.chatHeader.user_id);
            form_data.append('company_id', this.chatHeader.company_id);
            form_data.append('reciever_id', this.chatHeader.user_id);
            form_data.append('message', this.message);
            form_data.append('job_id', this.chatHeader.job_id);
            form_data.append('document', e.target.files[0]);
            const payload = {
                user_id: this.chatHeader.user_id,
                document: form_data,
                company_id: this.chatHeader.company_id,
                reciever_id: this.chatHeader.user_id,
                message: this.message,
                job_id: this.chatHeader.job_id,
            }
            //console.log(payload);
            const ProgressConfig = {
                onUploadProgress: (progressEvent) => {
                    this.uploadPercentage = Math.round((progressEvent.loaded / progressEvent.total) * 100);
                }
            }
            CandidateService.SendDoc(form_data, ProgressConfig)
                .then((res) => {
                    this.message = null
                    this.uploadPercentage = null
                    this.uploadingFileIcon = null
                    this.sending = false
                    //console.log('res', res);
                    this.chatMessages = this.chatMessages.filter((m) => m.message_id = 'uploading').pop(m)
                })
                .catch((error) => {

                    //console.log('error', error);
                })
        },
        sendChat() {
            this.sending = true
            if (this.message !== null && this.message !== "") {
                const payload = {
                    company_id: this.chatHeader.company_id,
                    reciever_id: this.chatHeader.user_id,
                    message: this.message,
                    job_id: this.chatHeader.job_id,
                }

                CandidateService.SendChatMessages(payload)
                    .then((res) => {
                        //console.log('res', res.data);
                        this.message = null
                        this.sending = false
                        this.getLastMessage()
                    })
                    .catch((error) => {
                        //console.log(error);
                    })
            }
        },
        async PrefilledData() {
            const loader = this.$loading.show()
            try {
                this.chatList = await this.getChatList()
                this.chatList.length && this.getMessage(this.chatList[0])
                // //console.log("ChatList", this.chatList);
            } catch (error) {
                //console.log(error);
            }
            loader.hide()
        }
    },
    created() {
        // addEventListener("resize", (e) => {
        //     this.isMobileMenu = window.innerWidth <= 992
        // });
        if (window.innerWidth >= 992) {
            this.ellipsis = (window.innerWidth / 3) - 500
        }
        else {
            this.ellipsis = window.innerWidth - 250
            addEventListener("resize", (e) => {
                this.ellipsis = window.innerWidth - 250
            });
        }
    },
    beforeMount() {
        this.PrefilledData()
        if (window.innerWidth >= 992) {
            this.ellipsis = 992 - (window.innerWidth / 3) - 500
        }
        else {
            this.ellipsis = window.innerWidth - 250
            addEventListener("resize", (e) => {
                this.ellipsis = window.innerWidth - 250
            });
        }

    },
    beforeDestroy() {
        clearInterval(this.interval);
    },
    // mounted() {
    //     window.addEventListener("click", this.handleClickOutside);
    // },
    // beforeUnmount() {
    //     window.removeEventListener("click", this.handleClickOutside);
    // },
}
</script>

<style>
.chat-navBar {
    padding: 0.75rem 0.75rem;
}

.chat-active-navBar {
    border-bottom: 2px solid var(--ai-pplicant-logo, #F36F21);
    opacity: 1;
}

/* #chat-input-box {
    position: absolute;
    bottom: -16px;
    left: 0;
    right: 0;

} */

.chat-send-icon {
    margin-left: 1rem;
    padding: 3px 5px 7px 5px;
    border-radius: 0.45456rem;
    background: var(--ai-pplicant-logo, #F36F21);
}

.zc-text-ellipsis {

    width: 8vw;
    overflow: hidden;
    text-wrap: nowrap;
    text-overflow: ellipsis;
}


.img-vid-file {
    font-size: 3rem;
    color: #12437e;
}

.pdf-file {
    font-size: 3rem;
    color: #dd2025;
}

.word-file {
    font-size: 3rem;
    color: #2063bd;
}

.file {
    font-size: 3rem;
    color: #000000bd;
}

@media screen and (max-width:361) {
    .chat-search {
        width: 100% !important;
    }
}
</style>

